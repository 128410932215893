.header {
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #000;
}

.header * {
  color: #ffffff;
}

.header_links {
  display: flex;
  align-items: center;
  gap: 2.5rem;
}

.header_links a {
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
}

.header_links .link_wrapper {
  position: relative;
  /*z-index: 3;*/
}

.header_links .link_children {
  position: absolute;
  top: 45px;
  left: -30%;
  background: #000;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: fit-content;
}

.header_links .link_children a {
  white-space: nowrap;
}

@media screen and (max-width: 991px) {
  .header_links {
    gap: 1.5rem;
  }
}

.close_icon,
.hamburger {
  display: none;
}

@media screen and (max-width: 768px) {
  .header {
    padding: 1rem;
  }

  .header img {
    width: 100px;
  }

  .header_links {
    position: fixed;
    width: 200px;
    padding: 1.5rem;
    top: 0;
    bottom: 0;
    z-index: 1111111;
    background: #000;
    flex-direction: column;
    align-items: flex-start;
    transition: 0.3s all ease-in-out;
  }

  .hamburger,
  .header_links .close_icon {
    display: block;
    width: fit-content;
    cursor: pointer;
    margin-left: auto;
  }

  .header_links a {
    font-size: 16px;
    font-weight: 600;
  }
}
