.parentAdvocacyAim{
    display: flex;
}
.parentAdvocacyAimRev{
    display: flex;
}
.childAdvocacyAim1{
    width: 50%;
    padding: 0 4% 0 4%;
}
.childAdvocacyAim2{
    width: 50%;
    padding: 0 4% 0 4%;
}
.advocacyAimImg{
    width: 100%;
    height: 450px;
    border-radius: 10px;
}

@media screen and (max-width: 768px) {
    .parentAdvocacyAim{
        flex-direction: column;
    }
    .parentAdvocacyAimRev{
        flex-direction: column-reverse;
    }
    .childAdvocacyAim1{
        width: 100%;
        padding: 0 4% 0 4%;
    }
    .childAdvocacyAim2{
        width: 100%;
        padding: 0 4% 0 4%;
    }
}
@media screen and (max-width: 575px) {
    .advocacyAimImg{
        height: 350px;
    }
    .childAdvocacyAim1{
        padding: 0 0 0 0;
    }
    .childAdvocacyAim2{
        padding: 0 0 0 0;
    }
}

