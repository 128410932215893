.parentToursEvent{
    display: flex;
}
.childToursEvent1{
    width: 40%;
}
.childToursEvent2{
    width: 40%;
}
.childToursEvent3{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.toursEventDiv{
    padding-left: 12%;
    padding-right: 12%;
}
.teHead{
    font-size: 28px;
    font-weight: 750;
}
.teDesc{
    font-size: 25px;
    font-weight: 550;
}
.teHeadHide{
    display: block;
    font-size: 28px;
    font-weight: 750;
    visibility: hidden;
}

@media screen and (max-width: 1024px) {
    .teHead{
        font-size: 22px;
    }
    .teHeadHide{
        font-size: 22px;
    }
    .teDesc{
        font-size: 19px;
    }
}

@media screen and (max-width: 768px) {
    .teHead{
        font-size: 18px;
    }
    .teHeadHide{
        font-size: 18px;
    }
    .teDesc{
        font-size: 15px;
    }
    .childToursEvent1{
        width: 50%;
    }
    .childToursEvent2{
        width: 30%;
    }
    .childToursEvent3{
        width: 20%;
    }
    .teTicketBtn{
        font-size: 12px;
    }
}

@media screen and (max-width: 575px) {
    .parentToursEvent{
        display: flex;
        flex-direction: column;
    }
    .teHead{
        font-size: 18px;
    }
    .teDesc{
        font-size: 15px;
    }
    .childToursEvent1{
        width: 100%;
        text-align: center;
    }
    .childToursEvent2{
        width: 100%;
        text-align: center;
    }
    .childToursEvent3{
        width: 100%;
        text-align: center;
    }
    .teTicketBtn{
        font-size: 10px;
    }
    .teHeadHide{
        display: none;
    }
}