.video-background {
    position: relative;
    width: 100%;
    height: 80vh; /* Adjust this as needed */
    overflow: hidden;
}
.video-background .video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    transform: translate(-50%, -50%);
}
.video-background .content {
    width: 100%;
    height: 100%;
    position: absolute;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5% 18% 5% 18%;
}
.bannerHead{
    font-weight: 600;
    font-size: 35px;
}
.bannerDesc{
    font-weight: 900;
    font-size: 70px;
}
.bannerBtn{
    font-weight: 500;
    font-size: 16px;
}

@media screen and (max-width: 1024px) {
    .bannerHead{
        font-weight: 600;
        font-size: 30px;
    }
    .bannerDesc{
        font-weight: 900;
        font-size: 60px;
    }
}
@media screen and (max-width: 768px) {
    .video-background .content {
        padding: 8% 18% 8% 18%;
    }
    .bannerHead{
        font-weight: 600;
        font-size: 25px;
    }
    .bannerDesc{
        font-weight: 900;
        font-size: 45px;
    }
}
@media screen and (max-width: 575px) {
    .video-background {
        position: relative;
        width: 100%;
        height: 70vh; /* Adjust this as needed */
        overflow: hidden;
    }
    .video-background .content {
        padding: 20% 10% 20% 10%;
    }
    .bannerHead{
        font-weight: 600;
        font-size: 25px;
    }
    .bannerDesc{
        font-weight: 900;
        font-size: 28px;
    }
}