.CCImg{
    height: 350px;
    width: 80%;
}
.CCText{
    width: 80%;
    font-size: 18px;
}
@media screen and (max-width: 575px) {
    .CCImg{

        width: 100%;
    }
    .CCText{
        width: 100%;
    }
}