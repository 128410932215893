@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Raleway'; /* Added font-family */
}
.section{
    padding: 4% 4% 4% 4%;
}
.head{
    font-size: 50px;
    font-weight: 650;
    padding-bottom: 2%;
}
.desc{
    font-size: 22px;
    padding-bottom: 2%;
    font-weight: 550;
}
.cWhite{
    color: white!important;
}
.bBlack{
    background: black;
}
.text-right{
    text-align: right;
}
.link{
    text-transform: none;
    text-decoration: none;
    color: inherit;
}
@media screen and (max-width: 575px)  {
    .section{
        padding: 10% 4% 10% 4%;
    }
    .head{
        font-size: 35px;
        font-weight: 650;
        padding-bottom: 2%;
    }
    .desc{
        font-size: 16px;
        padding-bottom: 2%;
        font-weight: 550;
    }
}
