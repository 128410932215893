.parentCB{
    display: flex;
}
.childCB1{
    width: 50%;
}
.childCB2{
    width: 50%;
}
.csBannerText{
    font-size: 40px;
    font-weight: 900;
}

@media screen and (max-width: 1024px) {
    .csBannerText{
        font-size: 35px;
    }
}

@media screen and (max-width: 768px) {
    .csBannerText{
        font-size: 28px;
    }
}

@media screen and (max-width: 575px) {
    .childCB1{
        width: 0;
    }
    .childCB2{
        width: 100%;
    }
    .csBannerText{
        font-size: 28px;
    }
}