.advocacyBanner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Needed for positioning the overlay */
    width: 100%;
    height: 100vh; /* Full viewport height */
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the image */
    color: white;
}

@media screen and (max-width: 768px) {
    .advocacyBanner {
        height: 80vh; /* Full viewport height */
        background-size: cover; /* Cover the entire div */
    }
}