.homeAlbum {
    position: relative; /* Needed for positioning the overlay */
    width: 100%;
    /*height: 800px; !* Full viewport height *!*/
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the image */
}

.homeAlbum .overlay {
    position: absolute; /* Overlay covers the background div */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    z-index: 1; /* Ensure the overlay is above the background image */
}
.homeAlbum .content {
    position: relative; /* Position the content above the overlay */
    z-index: 2; /* Ensure content is above the overlay */
    color: white;
}
.parentHomeAlbum{
    display: flex;
}
.childHomeAlbum1{
    width: 45%;
    padding: 0 2% 0 8%;
    /*background: lightgreen;*/
}
.childHomeAlbum2{
    width: 55%;
    padding: 0 8% 0 2%;
    /*background: lightgreen;*/
}
.homeAlbumImg{
    width: 100%;
    height: 550px;
    border-radius: 10px;
}
.homeAlbumDesc{
    font-size: 28px;
}

@media screen and (max-width: 1280px) {
    .homeAlbumDesc{
        font-size: 25px;
    }
}
@media screen and (max-width: 1024px) {
    .homeAlbumDesc{
        font-size: 22px;
    }
    .homeAlbumImg{
        width: 100%;
        height: 450px;
        border-radius: 10px;
    }
}
@media screen and (max-width: 768px) {
    .parentHomeAlbum{
        display: flex;
        flex-direction: column;
    }
    .childHomeAlbum1{
        width: 100%;
        padding: 0 8% 0 8%;
        /*background: lightgreen;*/
    }
    .childHomeAlbum2{
        width: 100%;
        padding: 0 8% 0 8%;
        /*background: lightgreen;*/
    }
    .homeAlbumDesc{
        text-align: center;
    }
    .homeAlbumBtnDiv{
        text-align: center;
    }
}
@media screen and (max-width: 575px) {

    .childHomeAlbum1{
        padding: 0 0 0 0;
        /*background: lightgreen;*/
    }
    .childHomeAlbum2{
        padding: 0 0 0 0;
        /*background: lightgreen;*/
    }
    .homeAlbumDesc{
        font-size: 20px;
    }
}