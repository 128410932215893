.subscribeFormDiv{
    display: flex;
    justify-content: center;
}
.subscribeForm{
    width: 50%;
}
@media screen and (max-width: 575px)  {
    .subscribeForm{
        width: 100%;
    }
}