.footer {
  background: #ffffff;
}

.footer > div {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer_btn {
  width: 200px;
  all: unset;
  text-transform: uppercase;
  color: #000;
  border: 1px solid #000;
  transition: all 0.2s ease-in-out;
  padding: 14px 10px;
  font-size: 14px;
}

.footer_btn:hover {
  background: #000;
  color: #ffffff;
}

.footer_input {
  border: 1px solid #aaa;
  padding: 8px 14px;
  width: 90%;
}

.social_icons {
  margin-top: 1rem;
  justify-content: center !important;
}

@media screen and (max-width: 500px) {
  .footer_btn {
    padding: 10px 8px;
    font-size: 13px;
    white-space: nowrap;
  }
  .footer_input {
    width: 70%;
  }
}
